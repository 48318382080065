import "./App.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Plane } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import React, { useRef, useState, Suspense, useEffect } from "react";
import { MeshBasicMaterial, Color } from "three";
import * as THREE from "three";

const Scene2 = ({ setScene, setMessage, messageNumber, setMessageNumber }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [isDoneTutorial, setIsDoneTutorial] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  const handleClick = (messageNumber) => {
    if (isDoneTutorial && !wasClicked) {
      console.log("Clicked!");
      setIsAnimating(!isAnimating);

      setWasClicked(true);
    } else if (!isDoneTutorial && !wasClicked) {
      setMessageNumber(messageNumber + 1);
    }
  };

  const fbx = useLoader(
    FBXLoader,
    "/donny-animation-1/source/Donny_toUnity_7.fbx"
  );

  const mesh = useRef();
  const dummy = useRef(new THREE.Object3D());

  const [mixer] = useState(() => new THREE.AnimationMixer(fbx));

  // Create animations
  const animations = fbx.animations.map((clip) => mixer.clipAction(clip));

  // Play the first animation
  const playAnimation = () => {
    if (animations.length > 0) {
      animations[0].play();
    }
  };

  useEffect(() => {
    console.log("isAnimating", isAnimating);
    if (isAnimating) playAnimation();
    else animations[0].stop();
  }, [isAnimating]);

  useEffect(() => {
    if (messageNumber === -2 && !isDoneTutorial) setMessageNumber(0);
    else if (messageNumber === 0) {
      setMessage("Hello my name is Stuart!");
    } else if (messageNumber === 1) {
      setMessage("Look! I have something poking out of my mouth...");
    } else if (messageNumber === 2) {
      setMessage("Maybe it's something Andy left for you...");
    } else if (messageNumber === 3) {
      setMessage("Try clicking on it!");
      setMessageNumber(-1);
      setIsDoneTutorial(true);
    }
  }, [messageNumber]);

  useEffect(() => {
    if (wasClicked) {
      setMessage("HAH! I tricked you! Stuart just wants to dance...");
      setTimeout(() => {
        setMessage("Okay, no but seriously, I'm working on it...");
      }, 5000);
      setTimeout(() => {
        setMessage("Calibrating to Camila's tastes... uhh once more...");
      }, 9000);
      setTimeout(() => {
        setScene(3);
      }, 11500);
    }
  }, [wasClicked]);

  useFrame((state, dt) => {
    dummy.current.lookAt(state.mouse.x, state.mouse.y, 0.8);
    mesh.current.quaternion.slerp(dummy.current.quaternion, 0.15);

    // Update the animation mixer
    if (mixer) {
      mixer.update(dt);
    }
  });
  return (
    <mesh
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      ref={mesh}
      onClick={() => handleClick(messageNumber)}
      scale={[0.025, 0.025, 0.025]} // Adjust the scale as needed
    >
      <primitive object={fbx} />
    </mesh>
  );
};

const Scene1 = ({ setScene, setMessage }) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  const handleClick = () => {
    console.log("Clicked!");
    setMessage("Calibrating to Camila's tastes...");
    setScene(2);
  };

  const fbx = useLoader(FBXLoader, "/valentines-day/source/scene1.fbx");

  const mesh = useRef();
  const dummy = useRef(new THREE.Object3D());

  // Rotate by 180 degrees
  useEffect(() => {
    if (mesh.current) {
      mesh.current.rotation.y = Math.PI; // Rotate by 180 degrees
      // translate down by 10
      mesh.current.position.set(-2, -1, -1);
    }
  }, []);

  return (
    <mesh
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      ref={mesh}
      onClick={handleClick}
      scale={[0.02, 0.02, 0.02]} // Adjust the scale as needed
    >
      <primitive object={fbx} />
    </mesh>
  );
};

const Scene3 = ({
  setScene,
  setMessage,
  messageNumber,
  setMessageNumber,
  noClicked,
  setNoClicked,
}) => {
  const [hovered, setHovered] = useState(false);
  const [isDoneTutorial, setIsDoneTutorial] = useState(false);
  const [distance, setDistance] = useState(-8);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  const handleClick = () => {
    if (!isDoneTutorial) {
      setMessageNumber(messageNumber + 1);
    }
  };

  const fbx = useLoader(FBXLoader, "/valentines-day/source/cuteduck.fbx");

  const mesh = useRef();
  const dummy = useRef(new THREE.Object3D());

  const hasAnimation = fbx.animations.length > 0;
  console.log("hasAnimation", hasAnimation);

  const missedArr = [
    "Wait! I think I saw daldon over there...",
    "I think I just saw someone pass by with enoki mushrooms...",
    "Is that cream brulee the waiter was holding?",
    "Is that wagyu beef and kimchi from daldon I see?",
    "Woaah, this arcade looks fun! I wonder who I should go with...",
  ];

  const [randomIndex, setRandomIndex] = useState(
    Math.floor(Math.random() * missedArr.length)
  );

  const incrementIndex = () => {
    // Get the next index by incrementing the current index
    let nextIndex = randomIndex + 1;

    // If the next index exceeds the max index, reset it to 0
    if (nextIndex >= missedArr.length) {
      nextIndex = 0;
    }

    // Update the state with the new index
    setRandomIndex(nextIndex);
  };

  useEffect(() => {
    if (messageNumber === -1 && !noClicked) setMessageNumber(0);
    else if (messageNumber === 0) {
      setMessage("Parrot Bebe has a message for: CAMILA!");
    } else if (messageNumber === 1) {
      setMessage("Hmmm it says from: Andy.");
    } else if (messageNumber === 2) {
      setMessage("He wants to ask you something...");
      setDistance(distance + 1);
    } else if (messageNumber === 3) {
      setMessage("His question...");
      setDistance(distance + 1);
    } else if (messageNumber === 4) {
      setMessage("Iss...");
      setDistance(distance + 1);
    } else if (messageNumber === 5) {
      setMessage("Lemme see...");
    } else if (messageNumber === 6) {
      setMessage("The question is...");
      setDistance(distance + 1);
    } else if (messageNumber === 7) {
      setMessage("Hmm is this right 😱?");
    } else if (messageNumber === 8) {
      setMessage("He wants to know...");
      setDistance(distance + 1);
    } else if (messageNumber === 9) {
      setMessage("On this February 14th, 2024...");
    } else if (messageNumber === 10) {
      setMessage("Will you be my Valentine 💘, petite Camila?");
      setDistance(distance + 1);
      setMessageNumber(-2);
      setIsDoneTutorial(true);
    } else if (messageNumber === 11) {
      setMessage("That's great! I'll see you soon my love 😊");

      setTimeout(() => {
        setMessage("Parrot Bebe has a rose for you!🌹");
        setDistance(distance + 1);
      }, 3000);
    }
  }, [messageNumber]);

  useEffect(() => {
    if (noClicked) {
      // increment missedArr index

      setMessage(missedArr[randomIndex]);
      incrementIndex();
      mesh.current.rotation.y = Math.PI / 2; // Rotate by 180 degrees

      setTimeout(() => {
        setMessage("That was good timing...");
        mesh.current.rotation.y = -Math.PI / 2; // Rotate by 180 degrees
      }, 3000);

      setTimeout(() => {
        setMessage("Sorry, I missed your answer...");
        mesh.current.rotation.y = -Math.PI / 2; // Rotate by 180 degrees
      }, 4500);

      setTimeout(() => {
        setMessage("Let me ask again...");
      }, 6000);

      setTimeout(() => {
        setMessageNumber(10);
        setNoClicked(false);
      }, 7000);
    }
  }, [noClicked]);

  // Rotate by 180 degrees
  useEffect(() => {
    if (mesh.current) {
      mesh.current.rotation.y = -Math.PI / 2; // Rotate by 180 degrees
      // translate down by 10
      mesh.current.position.set(0, -1, distance);
    }
  }, [distance]);

  return (
    <mesh
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      ref={mesh}
      onClick={handleClick}
      scale={[0.02, 0.02, 0.02]} // Adjust the scale as needed
    >
      <primitive object={fbx} />
    </mesh>
  );
};

export default function App() {
  const beigeColor = new Color(0xc56b8c); // Hex color for beige
  const [scene, setScene] = useState(1); // 1 or 2 or 3
  const [message, setMessage] = useState("Click to play...");
  const [messageNumber, setMessageNumber] = useState(-2);
  const [noClicked, setNoClicked] = useState(false);

  if (scene === 1) {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            textAlign: "center",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {message}
        </div>
        <div
          style={{
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            position: "relative",
            zIndex: 0,
          }}
        >
          <Canvas
            style={{ width: "100%", height: "100%" }}
            camera={{ position: [2, 4, 8] }}
          >
            <Suspense fallback={null}>
              <ambientLight intensity={2} />
              <Plane args={[200, 200]} position={[-60, -70, -8]}>
                <meshBasicMaterial attach="material" color={beigeColor} />
              </Plane>

              <Scene1 setScene={setScene} setMessage={setMessage} />
            </Suspense>
          </Canvas>
        </div>
      </div>
    );
  } else if (scene == 2) {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            textAlign: "center",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {message}
          {messageNumber != -1 && messageNumber != -2 && (
            <div>
              <button
                onClick={() => {
                  setMessageNumber(messageNumber + 1);
                }}
              >
                next
              </button>
            </div>
          )}
        </div>
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          <Canvas
            style={{ width: "100%", height: "100%" }}
            camera={{ position: [0, 3, 8] }}
          >
            <Suspense fallback={null}>
              <ambientLight intensity={3} />
              <Plane args={[200, 200]} position={[-60, -70, -8]}>
                <meshBasicMaterial attach="material" color={beigeColor} />
              </Plane>
              <Scene2
                setScene={setScene}
                setMessage={setMessage}
                messageNumber={messageNumber}
                setMessageNumber={setMessageNumber}
              />
            </Suspense>
          </Canvas>
        </div>
      </div>
    );
  } else if (scene == 3) {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            textAlign: "center",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {message}
          {messageNumber != -1 &&
            messageNumber != -2 &&
            messageNumber !== 11 && (
              <div>
                <button
                  onClick={() => {
                    setMessageNumber(messageNumber + 1);
                  }}
                >
                  next
                </button>
              </div>
            )}
        </div>
        {messageNumber === -2 && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              textAlign: "center",
              width: "100%",
              marginBottom: "20%",
              bottom: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMessageNumber(11);
                }}
              >
                Yes
              </button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <button
                onClick={() => {
                  setMessageNumber(-1);
                  setNoClicked(true);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          <Canvas
            style={{ width: "100%", height: "100%" }}
            camera={{ position: [0, 3, 8] }}
          >
            <Suspense fallback={null}>
              <ambientLight intensity={8} />
              <Plane args={[200, 200]} position={[-60, -70, -8]}>
                <meshBasicMaterial attach="material" color={beigeColor} />
              </Plane>
              <Scene3
                setScene={setScene}
                setMessage={setMessage}
                messageNumber={messageNumber}
                setMessageNumber={setMessageNumber}
                noClicked={noClicked}
                setNoClicked={setNoClicked}
              />
            </Suspense>
          </Canvas>
        </div>
      </div>
    );
  }
}
